import * as yup from 'yup';


export const commonCurrencyExchangeMaintenanceValidation: { [x: string]: any; } = {
  fromCurrency: yup.string().required("Missing"),
  toCurrency: yup.string().required("Missing"),
  exchangeRate: yup.number().required("Missing"),
  effectiveDate: yup.date().required("Missing"),
};

export const createCurrencyExchangeMaintenanceValidation: { [x: string]: any; } = {
  ...commonCurrencyExchangeMaintenanceValidation,
};

export const createCurrencyExchangeMaintenanceValidationSchema = yup.object().shape(createCurrencyExchangeMaintenanceValidation);

