import { CurrencyExchangeRepoImpl } from "domain/repository/CurrencyExchange/CurrencyExchangeRepoImpl";
import { useCurrencyExchangeMaintenanceTracked } from "presentation/store/CurrencyExchange/CurrencyExchangeMaintenanceProvider";
import { CurrencyExchangeMaintenanceVM } from "presentation/viewModel/CurrencyExchange/CurrencyExchangeMaintenanceVM";
import { useMemo } from "react";

export const useCurrencyExchangeMaintenanceVM = () => {
    const [, setCurrencyExchangeMaintenanceState] = useCurrencyExchangeMaintenanceTracked();
    const currencyExchangeMainVM = useMemo(() =>
        CurrencyExchangeMaintenanceVM({
            dispatch: [setCurrencyExchangeMaintenanceState],
            repo: CurrencyExchangeRepoImpl(),
        }), [setCurrencyExchangeMaintenanceState])

    return currencyExchangeMainVM
}

