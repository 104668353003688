export const currencyExchangeMaintenanceRequiredFieldList: string[] = [
    'currencyExchange', 
];

export const CurrencyExchangeMaintenanceConstant = {    
    CATEGORY: "Invoice",
    TITLE: "Exchange Rate",
    FROM_CRURRENCY: "Currency(From)",
    TO_CRURRENCY: 'Currency(To)',
    EFF_DATE: 'Effective Date',
    EXCHANGE_RATE: 'Exchange Rate',
    BANK_NO: 'Bank No',
}
