
import { CurrencyExchangeEntity, EMPTY_CURRENCY_EXCHANGE_ENTITY } from "domain/entity/CurrencyExchange/CurrencyExchangeEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface CurrencyExchangeDropdownOptions {
    currencyExchangeTypeDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: CurrencyExchangeEntity,
}

export interface CurrencyExchangeMaintenanceModel {
    isLoading: boolean,
    isShowDtl: boolean,
    tableData: CurrencyExchangeEntity[],
    currentSelectedRow: CurrencyExchangeEntity,
    selectedRows: CurrencyExchangeEntity[],
    dynamicOptions: CurrencyExchangeDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
}

export const EMPTY_MASTER_CURRENCY_EXCHANGEANY_MODEL: CurrencyExchangeMaintenanceModel = {
    isLoading: false,
    isShowDtl: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_CURRENCY_EXCHANGE_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        currencyExchangeTypeDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_CURRENCY_EXCHANGE_ENTITY,
    },
    isBackMaster: false,
}