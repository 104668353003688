
export interface CurrencyExchangeEntity {
    id:number,
    fromCurrency: string,
    toCurrency: string,
    currencyDesc: string|null,
    exchangeRate: number|null,
    effectiveDate: Date|null,
    expiryDate: Date|null,
    activeInd: string|null,
    bankNo: string|null,


    [key:string]: string | number | Date | null | undefined
}

export const EMPTY_CURRENCY_EXCHANGE_ENTITY:CurrencyExchangeEntity = {
    id: 0,
    fromCurrency: "",
    toCurrency: "",
    currencyDesc: "",
    exchangeRate: null,
    activeInd: "Y",
    effectiveDate: null,
    expiryDate: null,
    bankNo: null
}

