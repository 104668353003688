import { CurrencyExchangeMaintenanceConstant } from "presentation/constant/CurrencyExchange/CurrencyExchangeMaintenanceConstant";
import { useCurrencyExchangeMaintenanceVM } from "presentation/hook/CurrencyExchange/useCurrencyExchangeMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCurrencyExchangeMaintenanceTracked } from "presentation/store/CurrencyExchange/CurrencyExchangeMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CurrencyExchangeEditPanelTitleBar: React.FC = () => {
    const [currencyExchangeState] = useCurrencyExchangeMaintenanceTracked();
    const currencyExchangeVM = useCurrencyExchangeMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = currencyExchangeState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [anainfoState] = useANAInfoTracked();
    const { allowCreate, allowUpdate } = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        currencyExchangeVM.onReset();
    }, [currencyExchangeVM]);

    const handleEdit = useCallback(() => {
        currencyExchangeVM.onEditClicked();
    }, [currencyExchangeVM])

    const handleSave = useCallback(async () => {

        setIsLoading(true);
        try {
            currencyExchangeVM.onSaveClicked();
            const res = await currencyExchangeVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showError(res?.data ?? 'Save data failed.');
            } else {
                currencyExchangeVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, currencyExchangeVM]);

    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: CurrencyExchangeMaintenanceConstant.CATEGORY },
            { title: CurrencyExchangeMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!(isEditable || isAdd)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (!(isEditable || isAdd) && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={currencyExchangeVM.onClose} />
                </>}
            {((isEditable || isAdd)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={currencyExchangeVM.onClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(CurrencyExchangeEditPanelTitleBar);