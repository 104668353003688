
import { useCurrencyExchangeMaintenanceTracked } from "presentation/store/CurrencyExchange/CurrencyExchangeMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import CurrencyExchangeMaintenance from "./CurrencyExchangeMaintenance";
import { CurrencyExchangeMaintenanceFormPanel } from "./CurrencyExchangeMaintenanceFormPanel";

export const CurrencyExchangeMasterView: React.FC = () => {
    const [currencyExchangeState] = useCurrencyExchangeMaintenanceTracked();

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <CurrencyExchangeMaintenance />
            {
                ((currencyExchangeState.isShowDtl) &&
                    <SliderPanel
                        isOpen={false}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<CurrencyExchangeMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
}