import { EMPTY_MASTER_CURRENCY_EXCHANGEANY_MODEL } from "presentation/model/CurrencyExchange/CurrencyExchangeMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: CurrencyExchangeMaintenanceProvider,
    useTracked: useCurrencyExchangeMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_CURRENCY_EXCHANGEANY_MODEL), {concurrentMode: true});
export { CurrencyExchangeMaintenanceProvider, useCurrencyExchangeMaintenanceTracked };

